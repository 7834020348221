@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Raleway:wght@700&family=Space+Mono&display=swap);
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.authenticating-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
.authenticating {
  color: rgb(73, 73, 73);
  font-size: 2rem;
  margin-bottom: 1rem;
}
html {
  overflow: hidden;
}
.color-white {
  color: white !important;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}
.react-contextmenu-wrapper {
}
.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  background-color: #20a0ff;
  border-color: #20a0ff;
  color: #fff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.dropdown {
  background-color: #090b10;
  border: 1px solid #222;
  border-radius: 8px;
  overflow: hidden;
  padding: 1rem;
  position: absolute;
  right: 2px;
  top: 45px;
  transition: all 500ms ease;
  width: 300px;
  z-index: 100;
}

.dropdown-hidden {
  padding: 0 1rem;
  visibility: hidden;
}

.menu {
  width: 100%;
}

.menu-item {
  align-items: center;
  background-color: #0b0e14;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  display: flex;
  height: 50px;
  margin: 0.5rem 0;
  padding: 0.1rem;
  transition: background 500ms;
  width: calc(300px);
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  -webkit-filter: none;
          filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.back-button {
  align-items: center;
  background-color: #0b0e14;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  transition: background 500ms;
  width: 30px;
}

.back-button:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
  margin-right: 10px;
}

/* CSSTransition classes  */

.menu-forward-enter {
  transform: translateX(110%);
}
.menu-forward-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.menu-forward-exit {
  position: absolute;
}
.menu-forward-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
}

.menu-reverse-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-reverse-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.menu-reverse-exit {
}
.menu-reverse-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}

.MuiCircularProgress-colorPrimary {
  color: #3498db !important;
}

.edit-model-modal {
  width: 40%;
}

.custom-input {
  width: 100%;
}

.edit-model-modal-content {
  height: 70px;
}
.delete-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.delete-chart {
  font-weight: bold;
  padding: 10px;
}
.delete-success {
  color: #00e676;
}
.delete-error {
  color: #ff1744;
}

.save-model-modal {
  width: 40%;
}

.custom-input {
  width: 100%;
}

.save-model-modal-content {
  height: 70px;
}
.delete-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.delete-chart {
  font-weight: bold;
  padding: 10px;
}
.delete-success {
  color: #00e676;
}
.delete-error {
  color: #ff1744;
}

.transparent {
  opacity: 0;
}

.node {
  position: absolute;
  background: transparent no-repeat 50% / cover;
  border: 1px white solid;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.8s;
  cursor: pointer;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
}

.node.hide {
  opacity: 0;
  pointer-events: none;
}
.node.hide .label {
  -webkit-filter: blur(10px);
          filter: blur(10px);
}

.node:hover .label {
  color: hsla(0, 0, 100, 1);
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

#tooltip {
  position: relative;
}

