.delete-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.delete-chart {
  font-weight: bold;
  padding: 10px;
}
.delete-success {
  color: #00e676;
}
.delete-error {
  color: #ff1744;
}
