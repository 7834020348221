.dropdown {
  background-color: #090b10;
  border: 1px solid #222;
  border-radius: 8px;
  overflow: hidden;
  padding: 1rem;
  position: absolute;
  right: 2px;
  top: 45px;
  transition: all 500ms ease;
  width: 300px;
  z-index: 100;
}

.dropdown-hidden {
  padding: 0 1rem;
  visibility: hidden;
}

.menu {
  width: 100%;
}

.menu-item {
  align-items: center;
  background-color: #0b0e14;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  display: flex;
  height: 50px;
  margin: 0.5rem 0;
  padding: 0.1rem;
  transition: background 500ms;
  width: calc(300px);
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.back-button {
  align-items: center;
  background-color: #0b0e14;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  transition: background 500ms;
  width: 30px;
}

.back-button:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
  margin-right: 10px;
}

/* CSSTransition classes  */

.menu-forward-enter {
  transform: translateX(110%);
}
.menu-forward-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.menu-forward-exit {
  position: absolute;
}
.menu-forward-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
}

.menu-reverse-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-reverse-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.menu-reverse-exit {
}
.menu-reverse-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}
