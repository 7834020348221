.edit-model-modal {
  width: 40%;
}

.custom-input {
  width: 100%;
}

.edit-model-modal-content {
  height: 70px;
}