.authenticating-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
.authenticating {
  color: rgb(73, 73, 73);
  font-size: 2rem;
  margin-bottom: 1rem;
}
html {
  overflow: hidden;
}
.color-white {
  color: white !important;
}
