.transparent {
  opacity: 0;
}

.node {
  position: absolute;
  background: transparent no-repeat 50% / cover;
  border: 1px white solid;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.8s;
  cursor: pointer;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
}

.node.hide {
  opacity: 0;
  pointer-events: none;
}
.node.hide .label {
  filter: blur(10px);
}

.node:hover .label {
  color: hsla(0, 0, 100, 1);
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

#tooltip {
  position: relative;
}
